import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const themeConfig = {
  app: {
    // name: 'PI-TOOLS',
    logo: require('@/assets/images/logo.png'),


    full_logo: require('@/assets/images/full_logo.png'),
    full_logo_white: require('@/assets/images/full_logo_white.png'),

    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'default', // default, bordered, semi-dark
    contentWidth: 'boxed',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'static', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#1d4f91',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#E0004D',
      'alt-primary': '#1d4f91',
    },
    dark: {
      primary: '#1D4F91',
      accent: '#0d6efd',
      secondary: '#F1F1F1',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#ff7075',
      'alt-primary': '#e6e4ec',
    },
  },
}

export default themeConfig
