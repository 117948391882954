<template>
  <v-fade-transition mode="out-in">
    <v-icon :class="$vuetify.theme.dark ? 'tabindex-handler-light' : 'tabindex-handler'" class="me-3 pa-1 tabindex-handler" aria-label="change-theme"
      :key="isDark"
      @click="isDark = !isDark"
    >
      {{ isDark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
    <!-- </div> -->
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  setup() {
    const { isDark } = useAppConfig()

    return {
      isDark,

      // Icons
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
    }
  },
}
</script>

<style lang="scss">
.tabindex-handler{
  &:focus {
    border-radius: 20px !important;
    outline: 1px solid var(--v-primary-base) !important;
  }
}

.tabindex-handler-light{
  &:focus {
    border-radius: 20px !important;
    outline: 1px solid white !important;
  }
}
</style>
