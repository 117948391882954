<template>
  <div @focusout="closeOnBlur($event)" class="d-flex align-center justify-center ps-6 pe-5 pt-5 pb-2">
    <v-slide-x-transition>
      <v-btn class="no-uppercase" :elevation="0" color="transparent" @click.stop="$emit('close-nav-menu')">
      {{$t('Close')}}
      <v-icon
        size="20"
        class="d-inline-block close-nav-btn ml-2"
      >
        {{ icons.mdiClose }}
      </v-icon>
      </v-btn>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'
import useAppConfig from '@core/@app-config/useAppConfig'
import themeConfig from '@themeConfig'
import { inject } from '@vue/composition-api'

export default {
  methods: {
    closeOnBlur(e) {
      if (e.relatedTarget?.attributes?.href?.nodeValue !== '/tutorial') this.$emit('close-nav-menu')
    }
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()
    const isMouseHovered = inject('isMouseHovered')

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}


</style>
