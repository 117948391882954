import { 
  mdiHomeOutline,
  mdiAccountMultipleOutline,
  mdiCardAccountDetailsOutline,
  mdiFileDocumentMultipleOutline,
  mdiHammerScrewdriver,
  mdiEarth,
  mdiSchool,
  mdiFormatListBulleted,
  mdiVideoVintage
} from '@mdi/js'

export default [

  { title: 'Home', icon: mdiHomeOutline, to: 'home' },
  {subheader: 'PQI Evaluation' },
  { title: 'Evaluaciones', icon: mdiFileDocumentMultipleOutline, to: 'evaluations' },
  // { title: 'Resultados', icon: mdiChartBar, to: 'results' },
  { title: 'Instrumentos', icon: mdiHammerScrewdriver, to: 'instruments' },
  {subheader: 'Usuario' },
  { title: 'Usuarios', icon: mdiAccountMultipleOutline, to: 'users' },
  { title: 'Paises', icon: mdiEarth, to: 'countries' },
  { title: 'Regiones', icon: mdiCardAccountDetailsOutline, to: 'regions' },
  { title: 'Escuelas', icon: mdiSchool, to: 'schools' },
  // {subheader: 'FAQs' },
  // { title: 'Tutorial', icon: mdiVideoVintage, to: 'tutorial' },

]