// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  }
})

axiosIns.interceptors.request.use(
  config => {
        // Do something before request is sent
    const sessionData = localStorage.getItem('session') || sessionStorage.getItem('session')
    const parsedData = JSON.parse(sessionData)
    const accessToken = parsedData?.access

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
    // console.log(parsedData)

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(response => {
  return response;
}, error => {
  if (error?.response?.data?.code === "token_not_valid") {
    console.log('token vencido')

    sessionStorage.removeItem('session')
    localStorage.removeItem('session')

    window.location.replace('login')
  }
  return Promise.reject(error);
});

//logIn
axiosIns.login = form => {
  return axiosIns.post(`api/account/login`, form)
    .then(response => response.data)
}

//logged-UserData
axiosIns.getUserDataForProfile = () => axiosIns.get(`api/user_information_profile/`)
  .then(response => response.data)

// Update Profile
axiosIns.updateUserDataForProfile = form => axiosIns.patch(`api/user_information_profile/`, form) /* CORREGIR */
  .then(response => response.data)

axiosIns.changeUserPasswordProfile = form => axiosIns.patch(`api/account/password/change`, form)
  .then(response => response.data)

axiosIns.sendResetPasswordEmail = form => axiosIns.post(`api/account/password/reset`, form)
  .then(response => response.data)

axiosIns.validateToken = form => axiosIns.post(`api/account/password/validate`, form)
  .then(response => response)

axiosIns.recoverPassword = form => axiosIns.post(`api/account/password/confirm`, form)
  .then(response => response)

// Users EPs
axiosIns.createUser = form => axiosIns.post(`api/account/register`, form)
  .then(response => response.data)

axiosIns.getUsersList = (data) => {
  let query = '?'

  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  if (data?.search && data?.search.length) query +=
    query.length >1?
      `&search=${data.search}` : `search=${data.search}`

  if (data?.role !== -1) query +=
    query.length > 1?
      `&role=${data.role}` : `role=${data.role}`

  if (data?.summary) query +=
    query.length > 1?
      '&summary=true&paginated=false' : 'summary=true&paginated=false'

  if (data?.country && data?.country !== -1 && data?.country !== '-') query +=
    query.length >1?
      `&country__name=${data.country}` : `country__name=${data.country}`

  if (data?.order) {
    query += query.length >1
      ? `&order=${data.order}`
      : `order=${data.order}`
  }

  return axiosIns.get(`api/user_information/${query.length > 1? query : ''}`)
    .then(response => response.data)
}

axiosIns.getUser = id => axiosIns.get(`api/user_information/${id}/`)
  .then(response => response)

axiosIns.updateUser = (id,form) => axiosIns.patch(`api/user_information/${id}/`, form)
  .then(response => response.data)

axiosIns.deleteUser = id => axiosIns.delete(`api/user_information/${id}/`)
  .then(response => response)

// Country EPs

axiosIns.getCountryList = (data) => {
  let query = '?'
  if (data.paginated === false) query += 'paginated=false'
  else {

    if (data?.page && data?.page_size) query +=
      query.length >1?
        `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

    if (data?.search && data?.search.length) query +=
      query.length >1?
        `&search=${data.search}` : `search=${data.search}`

    if (data?.order) {
      query += query.length >1
        ? `&order=${data.order}`
        : `order=${data.order}`
    }
  }

  return axiosIns.get(`api/country/${query.length > 1? query : ''}`)
    .then(response => response.data)
}

//Region EPs

axiosIns.getRegionList = (data) => {
  let query = '?'

  if (data.paginated === false) {
    query += 'paginated=false'
    if (data?.country && data?.country !== -1 && data?.country !== '-') query += `&country__name=${data.country}`
  }
  else {
    if (data?.page && data?.page_size) query +=
      query.length >1?
        `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

    if (data?.search && data?.search.length) query +=
      query.length >1?
        `&search=${data.search}` : `search=${data.search}`

    if (data?.summary) query +=
      query.length > 1?
        '&summary=true&paginated=false' : 'summary=true&paginated=false'

    if (data?.country && data?.country !== -1 && data?.country !== '-') query +=
      query.length >1?
        `&country__name=${data.country}` : `country__name=${data.country}`

    if (data?.order) {
      query += query.length >1
        ? `&order=${data.order}`
        : `order=${data.order}`
    }
  }

  return axiosIns.get(`api/region/${query.length > 1? query : ''}`)
    .then(response => response.data)
}

axiosIns.createRegion = (form) => axiosIns.post(`api/region/`,form)
  .then(response => response.data)

axiosIns.updateRegion = (id, form) => axiosIns.patch(`api/region/${id}/`,form)
  .then(response => response.data)

axiosIns.getRegion = (id) => axiosIns.get(`api/region/${id}/`)
  .then(response => response.data)

axiosIns.deleteRegion = (id) => axiosIns.delete(`api/region/${id}/`)
  .then(response => response.data)
//Area EPs

axiosIns.addArea = (form) => axiosIns.post(`api/area/`, form)
  .then(response => {
    return response.data
  }
  )

axiosIns.removeArea = (id) => axiosIns.delete(`api/area/${id}/`)
  .then(response => response.data)

//School EPs
axiosIns.getSchoolList = (data) => {
  let query = '?'
  if (data.paginated === false) {
    query += 'paginated=false'
    if (data?.region && data?.region !== -1 && data?.region !== '-') query += `&region__name=${data.region}`
  }
  else {
    if (data?.page && data?.page_size) query +=
      query.length >1?
        `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

    if (data?.search && data?.search.length) query +=
      query.length >1?
        `&search=${data.search}` : `search=${data.search}`

    if (data?.summary) query +=
      query.length > 1?
        '&summary=true&paginated=false' : 'summary=true&paginated=false'

    if (data?.country && data?.country !== -1 && data?.country !== '-') query +=
      query.length >1?
        `&country__name=${data.country}` : `country__name=${data.country}`

    if (data?.region && data?.region !== -1 && data?.region !== '-') query +=
      query.length >1?
        `&region__name=${data.region}` : `region__name=${data.region}`

    if (data?.order) {
      query += query.length >1
        ? `&order=${data.order}`
        : `order=${data.order}`
    }
  }

  return axiosIns.get(`api/school/${query.length > 1? query : ''}`)
    .then(response => response.data)
}

axiosIns.getSchool = (id) => axiosIns.get(`api/school/${id}/`)
  .then(response => response.data)

axiosIns.createSchool = (form) => axiosIns.post(`api/school/`,form)
  .then(response => response.data)

axiosIns.updateSchool = (id, form) => axiosIns.patch(`api/school/${id}/`,form)
  .then(response => response.data)

axiosIns.deleteSchool = (id) => axiosIns.delete(`api/school/${id}/`)
  .then(response => response.data)

axiosIns.rolesForCreate = () => axiosIns.get(`api/roles_for_create/`)
  .then(response => response.data)


// Evaluation EPs
axiosIns.getEvaluationList = (data) => {
  let query = '?'

  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  if (data?.search && data?.search.length) query +=
    query.length >1?
      `&search=${data.search}` : `search=${data.search}`

  if (data?.summary) query +=
    query.length > 1?
      '&summary=true&paginated=false' : 'summary=true&paginated=false'
    
  if (data?.country && data?.country !== -1 && data?.country !== '-') query +=
    query.length >1?
      `&country__name=${data.country}` : `country__name=${data.country}`
    
  if (data?.region && data?.region !== -1 && data?.region !== '-') query +=
    query.length >1?
      `&region__name=${data.region}` : `region__name=${data.region}`

  return axiosIns.get(`api/evaluation/${query.length > 1? query : ''}`)
    .then(response => response.data)
}

axiosIns.getEvaluation = (id) => axiosIns.get(`api/evaluation/${id}/`)
  .then(response => response.data)

axiosIns.createEvaluation = (form) => axiosIns.post(`api/evaluation/`, form)
  .then(response => response)

axiosIns.deleteEvaluation = (id) => axiosIns.delete(`/api/evaluation/${id}/`)
  .then(response => response)

axiosIns.changeEvaluationDate = (id, form) => axiosIns.patch(`api/evaluation/${id}/`, form)
  .then(response => response)

axiosIns.updateIndiPrin = (form) => axiosIns.patch(`api/evaluation/indiprin/`, form)
  .then(response => response)

axiosIns.getOptionswithScores = () => axiosIns.get(`/api/option/`)
  .then(response => response.data)

axiosIns.editOptionScore = (id, form) => axiosIns.patch(`/api/option/${id}/`, form)
  .then(response => response.data)

axiosIns.changePeriod = (id, form) => axiosIns.patch(`/api/evaluation/${id}/`, form)
  .then(response => response.data)

// Instruments EPs

axiosIns.getInstrumentList = () => axiosIns.get(`api/instruments/`)
  .then(response => response.data)

axiosIns.getInstrument = (id) => axiosIns.get(`api/instruments/${id}/`)
  .then(response => response.data)

axiosIns.postResult = (form) => axiosIns.post(`api/result/`, form)
  .then(response => response)

axiosIns.updateResult = (form, id) => axiosIns.patch(`api/result/${id}/`, form)
  .then(response => response)

axiosIns.deleteResult = (id) => axiosIns.delete(`api/result/${id}/`)
  .then(response => response)

axiosIns.uploadFile = (form) => axiosIns.post(`api/file/`, form, {headers: {'Content-Type': 'multipart/form-data'}})
  .then(response => response)

axiosIns.removeFile = (id) => axiosIns.delete(`api/file/${id}/`)
  .then(response => response)

axiosIns.downloadFile = (id) => axiosIns.get(`api/file/${id}/`, {responseType: 'arraybuffer',})
  .then(response => response)

axiosIns.addComment = (form) => axiosIns.post(`api/comment/`, form)
  .then(response => response)

axiosIns.removeComment = (id) => axiosIns.delete(`api/comment/${id}/`)
  .then(response => response)

axiosIns.editComment = (id, form) => axiosIns.patch(`api/comment/${id}/`, form)
.then(response => response)

// Results EPs

axiosIns.getResults = (id) => axiosIns.get(`api/result/${id}/`)
  .then(response => response.data)

axiosIns.createActionPlan = (form) => axiosIns.post(`api/action_plan/`, form)
  .then(response => response)

axiosIns.createGoalsAndResult = (form) => axiosIns.post(`api/goals/`, form)
  .then(response => response)

axiosIns.deleteGoalsAndResult = (id) => axiosIns.delete(`api/goals/${id}/`)
  .then(response => response)

axiosIns.updateGoalsAndResult = (id,form) => axiosIns.patch(`api/goals/${id}/`, form)
  .then(response => response)

axiosIns.createQuestion = (form) => axiosIns.post(`api/questions/`, form)
  .then(response => response)

axiosIns.editQuestion = (id,form) => axiosIns.patch(`api/questions/${id}/`, form)
  .then(response => response)

axiosIns.createObservation = (form) => axiosIns.post(`api/observations/`, form)
  .then(response => response)

axiosIns.updateObservation = (id, form) => axiosIns.patch(`api/observations/${id}/`, form)
  .then(response => response)

axiosIns.deleteObservation = (id) => axiosIns.delete(`api/observations/${id}/`)
  .then(response => response)

axiosIns.createActivity = (form) => axiosIns.post(`api/activities/`, form)
  .then(response => response)

axiosIns.updateActivity = (id, form) => axiosIns.patch(`api/activities/${id}/`, form)
  .then(response => response)

axiosIns.deleteActivity = (id) => axiosIns.delete(`api/activities/${id}/`)
  .then(response => response)

axiosIns.getNote = (id) => axiosIns.get(`api/note_evaluation/${id}/`)
  .then(response => response)

axiosIns.createNote = (form) => axiosIns.post(`api/note/`, form)
  .then(response => response)

axiosIns.editNote = (id, form) => axiosIns.patch(`api/note/${id}/`, form)
  .then(response => response)

axiosIns.deleteNote = (id) => axiosIns.delete(`api/note/${id}/`)
  .then(response => response)

axiosIns.getSchoolResults = (id) => axiosIns.get(`api/results_school/${id}/`)
  .then(response => response.data)

// REPORTS EPs

axiosIns.getReports = (data) => {
  let query = '?'

  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  return axiosIns.get(`api/report/${data.country ? 'country' : 'region'}/${data.id}/${query.length > 1? query : ''}`)
    .then(response => response.data)
  }

axiosIns.editReport = (form, id) => axiosIns.patch(`api/report/${id}/`, form)
  .then(response => response.data)

axiosIns.deleteReport = (id) => axiosIns.delete(`api/report/${id}/`)
  .then(response => response.data)

axiosIns.createReport = (form, data) => axiosIns.post(`api/report/${data.isCountry ? 'country' : 'region'}/${data.id}/`, form)
  .then(response => response.data)

// STATISCTICS EPs

axiosIns.getEvaluationStatistics = () => axiosIns.get(`api/statistics/evaluations`)
  .then(response => response.data)

axiosIns.getSchoolStatistics = () => axiosIns.get(`api/statistics/schools`)
  .then(response => response.data)


Vue.prototype.$api = axiosIns

export default axiosIns
