<template>
  <layout-blank>
    <slot></slot>
    <div class="lang-select d-flex">
      <app-bar-theme-switcher></app-bar-theme-switcher>
      <app-bar-i18n></app-bar-i18n>
    </div>
  </layout-blank>
</template>

<script>
import LayoutBlank from '@/@core/layouts/variants/blank/LayoutBlank.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'


export default {
  components: {
    LayoutBlank,
    AppBarThemeSwitcher,
    AppBarI18n
  },
}
</script>

<style scoped>
.lang-select {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 20px;
  margin-top: 10px;
}
</style>
