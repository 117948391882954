import { 
  mdiHomeOutline,
  mdiFileDocumentMultipleOutline,
  mdiSchool,
  mdiVideoVintage
} from '@mdi/js'

export default [

  { title: 'Home', icon: mdiHomeOutline, to: 'home' },
  {subheader: 'PQI Evaluation' },
  { title: 'Evaluaciones', icon: mdiFileDocumentMultipleOutline, to: 'evaluations' },
  {subheader: 'Usuario' },
  { title: 'Escuelas', icon: mdiSchool, to: 'schools' },
  // {subheader: 'FAQs' },
  // { title: 'Tutorial', icon: mdiVideoVintage, to: 'tutorial' },

]