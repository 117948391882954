<template>
  <v-menu
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <!-- the navigation across menu options is not available with tab but with the arrows keys -->
      <div tabindex="0"
        v-bind="attrs"
        :class="$vuetify.theme.dark ? 'tabindex-handler-i18n-light' : 'tabindex-handler-i18n'"
        class="d-flex align-center px-2 py-1  "
        v-on="on"
      >
        <v-img
          :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
          :alt="$i18n.locale"
          height="14px"
          width="22px"
          class="me-2"
        ></v-img>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $t(locales.find(l => l.locale === $i18n.locale).title) }}</span>
      </div>
    </template>

    <!-- Options List -->
    <v-list>
      <v-list-item-group
        :value="$i18n.locale"
        @change="updateActiveLocale"
      >
        <v-list-item
          v-for="locale in locales"
          :key="locale.locale"
          :value="locale.locale"
        >
          <v-img
            :src="locale.img"
            height="14px"
            width="22px"
            :alt="locale.locale"
            class="me-2"
          ></v-img>
          <v-list-item-title>{{ $t(locale.title) }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { loadLanguageAsync } from '@/plugins/i18n'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
  setup() {
    const { isRtl } = useAppConfig()

    const locales = [
      {
        title: 'English',
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },
      {
        title: 'Portuguese',
        img: require('@/assets/images/flags/pt.png'),
        locale: 'pt',
      },
      {
        title: 'Spanish',
        img: require('@/assets/images/flags/es.png'),
        locale: 'es',
      },
    ]

    const updateActiveLocale = locale => {
      sessionStorage.setItem('locale', locale)
      loadLanguageAsync(locale)
    }

    return {
      locales,
      updateActiveLocale,
    }
  },
}
</script>
<style lang="scss">
.tabindex-handler-i18n{
  &:focus {
    border-radius: 20px;
    // padding: 2px 11px;
    outline: 1px solid var(--v-primary-base);
  }
}

.tabindex-handler-i18n-light{
  &:focus {
    border-radius: 20px;
    // padding: 2px 11px;
    outline: 1px solid white;
  }
}
</style>
