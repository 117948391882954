import Vue from 'vue'
import VueRouter from 'vue-router'
import super_admin_private_url from '../middleware/super-admin-private-url';
import admin_private_url from '../middleware/admin-private-url';
import private_url from '../middleware/private-url';
import public_url from '../middleware/public-url';
import coordinator_private_url from '../middleware/coordinator-private-url';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    meta: {
      middleware: coordinator_private_url,
      layout: 'content',
    },
  },
  {
    path: '/schools',
    name: 'schools',
    component: () => import('@/views/Schools.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/schools/results/:id',
    name: 'schools-results',
    component: () => import('@/components/Schools/SchoolResults.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/evaluations',
    name: 'evaluations',
    component: () => import('@/views/Evaluations.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  // {
  //   path: '/results',
  //   name: 'results',
  //   component: () => import('@/views/Results.vue'),
  //   meta: {
  //     middleware: private_url,
  //     layout: 'content',
  //   },
  // },
  {
    path: '/instruments',
    name: 'instruments',
    component: () => import('@/views/Instruments.vue'),
    meta: {
      middleware: coordinator_private_url,
      layout: 'content',
    },
  },
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/views/Countries.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@/views/Statistics.vue'),
    meta: {
      middleware: super_admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/regions',
    name: 'regions',
    component: () => import('@/views/Regions.vue'),
    meta: {
      middleware: coordinator_private_url,
      layout: 'content',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      middleware: public_url,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      middleware: public_url,
      layout: 'blank',
    },
  },
  {
    path: '/forgotpassword',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      middleware: public_url,
      layout: 'blank',
    },
  },
  {
    path: '/account/recover',
    name: 'recover-password',
    component: () => import('@/views/RecoverPassword.vue'),
    meta: {
      middleware: public_url,
      layout: 'blank',
    },
  },
  {
    path: '/privacy',
    name: 'privacy-policies',
    component: () => import('@/views/PrivacyPolicies.vue'),
    meta: {
      middleware: public_url,
      layout: 'blank',
    },
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: () => import('@/views/Tutorial.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}
  
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router
